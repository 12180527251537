import React from 'react';
import './ResourcesTiles.css';
import { useLanguage } from '../LanguageContext';

const resources = [
  {
    id: '1',
    titleKey: 'resources.songLibrary.title',
    descKey: 'resources.songLibrary.desc',
    link: '#song-library'
  },
  {
    id: '2',
    titleKey: 'resources.sheetMusic.title',
    descKey: 'resources.sheetMusic.desc',
    link: '#sheet-music'
  },
  {
    id: '3',
    titleKey: 'resources.training.title',
    descKey: 'resources.training.desc',
    link: '#training'
  },
  {
    id: '4',
    titleKey: 'resources.schedule.title',
    descKey: 'resources.schedule.desc',
    link: '#schedule'
  },
  {
    id: '5',
    titleKey: 'resources.team.title',
    descKey: 'resources.team.desc',
    link: '#team'
  },
  {
    id: '6',
    titleKey: 'resources.equipment.title',
    descKey: 'resources.equipment.desc',
    link: '#equipment'
  }
];

export function ResourcesTiles() {
  const { t } = useLanguage();
  const [hoveredId, setHoveredId] = React.useState(null);

  return (
    <div className="resources-grid">
      {resources.map((resource) => (
        <a 
          key={resource.id}
          href={resource.link} 
          className="resource-card"
          onMouseEnter={() => setHoveredId(resource.id)}
          onMouseLeave={() => setHoveredId(null)}
        >
          <div className={`card-content ${hoveredId === resource.id ? 'hovered' : ''}`}>
            <h3 className="card-title">
              {t(resource.titleKey)}
            </h3>
            <p className="card-description">
              {t(resource.descKey)}
            </p>
          </div>
        </a>
      ))}
    </div>
  );
} 