export const newMemberTranslations = {
  en: {
    'newMember.title': 'New Member Guide',
    'newMember.backToGuide': 'Back to Guide',
    'newMember.getting-started.title': 'Getting Started',
    'newMember.getting-started.desc': 'Create your account and complete your profile with musical experience and preferences.',
    'newMember.getting-started.profile.title': 'Create Your Profile',
    'newMember.getting-started.profile.desc': 'Set up your musical profile by adding your instruments, experience level, and preferred musical styles.',
    'newMember.getting-started.profile.step1': 'Fill out the registration form',
    'newMember.getting-started.profile.step2': 'Add your musical background',
    'newMember.getting-started.profile.step3': 'Upload a profile photo',
    'newMember.getting-started.profile.step4': 'Set your availability preferences',
    'newMember.getting-started.training.title': 'Complete Initial Training',
    'newMember.getting-started.training.desc': 'Go through our foundational training materials to understand our worship philosophy and musical standards.',
    'newMember.getting-started.training.step1': 'Watch the welcome video',
    'newMember.getting-started.training.step2': 'Complete the musical assessment',
    'newMember.getting-started.training.step3': 'Review our worship guidelines',
    'newMember.getting-started.training.step4': 'Schedule an orientation meeting',
    'newMember.team.title': 'Team Integration',
    'newMember.team.desc': 'Join your church\'s worship team and connect with other members.',
    'newMember.team.meet.title': 'Meet the Team',
    'newMember.team.meet.desc': 'Get to know your fellow worship team members and leadership structure.',
    'newMember.team.meet.step1': 'Attend team meet and greet',
    'newMember.team.meet.step2': 'Join our communication channels',
    'newMember.team.meet.step3': 'Review team roles and responsibilities',
    'newMember.team.meet.step4': 'Connect with your section leader',
    'newMember.team.expectations.title': 'Team Expectations',
    'newMember.team.expectations.desc': 'Understand our team culture and what is expected of each member.',
    'newMember.team.expectations.step1': 'Review attendance policy',
    'newMember.team.expectations.step2': 'Learn about rehearsal preparation',
    'newMember.team.expectations.step3': 'Understand service commitments',
    'newMember.team.expectations.step4': 'Review dress code guidelines',
    'newMember.resources.title': 'Resources Access',
    'newMember.resources.desc': 'Get immediate access to sheet music, chord charts, and training materials.',
    'newMember.resources.access.title': 'Access Your Resources',
    'newMember.resources.access.desc': 'Learn how to access and use all the resources available to our team members.',
    'newMember.resources.access.step1': 'Set up Planning Center account',
    'newMember.resources.access.step2': 'Access chord charts and sheet music',
    'newMember.resources.access.step3': 'Download rehearsal tracks',
    'newMember.resources.access.step4': 'Access training videos',
    'newMember.resources.tech.title': 'Technical Setup',
    'newMember.resources.tech.desc': 'Get your equipment and software ready for effective participation.',
    'newMember.resources.tech.step1': 'Install required apps',
    'newMember.resources.tech.step2': 'Set up monitoring equipment',
    'newMember.resources.tech.step3': 'Configure your notifications',
    'newMember.resources.tech.step4': 'Test your setup',
    'newMember.schedule.title': 'Schedule Management',
    'newMember.schedule.desc': 'Learn how to view and respond to service scheduling requests.',
    'newMember.schedule.manage.title': 'Managing Your Schedule',
    'newMember.schedule.manage.desc': 'Learn how to effectively manage your service and rehearsal schedule.',
    'newMember.schedule.manage.step1': 'Set up availability blocks',
    'newMember.schedule.manage.step2': 'Respond to scheduling requests',
    'newMember.schedule.manage.step3': 'Use the calendar features',
    'newMember.schedule.manage.step4': 'Set up schedule reminders',
    'newMember.schedule.dates.title': 'Important Dates',
    'newMember.schedule.dates.desc': 'Keep track of key events and deadlines in our worship ministry.',
    'newMember.schedule.dates.step1': 'Mark team meeting dates',
    'newMember.schedule.dates.step2': 'Note special service times',
    'newMember.schedule.dates.step3': 'Track rehearsal schedules',
    'newMember.schedule.dates.step4': 'Set workshop dates',
    'newMember.communication.title': 'Communication',
    'newMember.communication.desc': 'Stay updated with team announcements and rehearsal information.',
    'newMember.communication.connect.title': 'Stay Connected',
    'newMember.communication.connect.desc': 'Learn about our various communication channels and how to stay updated.',
    'newMember.communication.connect.step1': 'Join the team chat group',
    'newMember.communication.connect.step2': 'Subscribe to announcements',
    'newMember.communication.connect.step3': 'Set up email notifications',
    'newMember.communication.connect.step4': 'Download mobile apps',
    'newMember.communication.guidelines.title': 'Communication Guidelines',
    'newMember.communication.guidelines.desc': 'Understand our communication protocols and best practices.',
    'newMember.communication.guidelines.step1': 'Learn response expectations',
    'newMember.communication.guidelines.step2': 'Understand emergency protocols',
    'newMember.communication.guidelines.step3': 'Know who to contact',
    'newMember.communication.guidelines.step4': 'Set communication preferences'
  },
  es: {
    'newMember.title': 'Guía de Nuevo Miembro',
    'newMember.backToGuide': 'Volver a la Guía',
    'newMember.getting-started.title': 'Comenzar',
    'newMember.getting-started.desc': 'Crea tu cuenta y completa tu perfil con experiencia musical y preferencias.',
    'newMember.getting-started.profile.title': 'Crea tu Perfil',
    'newMember.getting-started.profile.desc': 'Configura tu perfil musical agregando tus instrumentos, nivel de experiencia y estilos musicales preferidos.',
    'newMember.getting-started.profile.step1': 'Completa el formulario de registro',
    'newMember.getting-started.profile.step2': 'Agrega tu experiencia musical',
    'newMember.getting-started.profile.step3': 'Sube una foto de perfil',
    'newMember.getting-started.profile.step4': 'Configura tus preferencias de disponibilidad',
    'newMember.getting-started.training.title': 'Completa el Entrenamiento Inicial',
    'newMember.getting-started.training.desc': 'Revisa nuestros materiales de capacitación fundamentales para comprender nuestra filosofía de adoración y estándares musicales.',
    'newMember.getting-started.training.step1': 'Mira el video de bienvenida',
    'newMember.getting-started.training.step2': 'Completa la evaluación musical',
    'newMember.getting-started.training.step3': 'Revisa nuestras pautas de adoración',
    'newMember.getting-started.training.step4': 'Programa una reunión de orientación',
    'newMember.team.title': 'Integración al Equipo',
    'newMember.team.desc': 'Únete al equipo de adoración de tu iglesia y conéctate con otros miembros.',
    'newMember.team.meet.title': 'Conoce al Equipo',
    'newMember.team.meet.desc': 'Conoce a tus compañeros del equipo de adoración y la estructura de liderazgo.',
    'newMember.team.meet.step1': 'Asiste al encuentro del equipo',
    'newMember.team.meet.step2': 'Únete a nuestros canales de comunicación',
    'newMember.team.meet.step3': 'Revisa los roles y responsabilidades',
    'newMember.team.meet.step4': 'Conéctate con tu líder de sección',
    'newMember.team.expectations.title': 'Expectativas del Equipo',
    'newMember.team.expectations.desc': 'Comprende nuestra cultura de equipo y lo que se espera de cada miembro.',
    'newMember.team.expectations.step1': 'Revisa la política de asistencia',
    'newMember.team.expectations.step2': 'Aprende sobre la preparación para ensayos',
    'newMember.team.expectations.step3': 'Comprende los compromisos de servicio',
    'newMember.team.expectations.step4': 'Revisa las pautas de vestimenta',
    'newMember.resources.title': 'Acceso a Recursos',
    'newMember.resources.desc': 'Obtén acceso inmediato a partituras, acordes y materiales de entrenamiento.',
    'newMember.resources.access.title': 'Accede a tus Recursos',
    'newMember.resources.access.desc': 'Aprende a acceder y utilizar todos los recursos disponibles para los miembros del equipo.',
    'newMember.resources.access.step1': 'Configura tu cuenta de Planning Center',
    'newMember.resources.access.step2': 'Accede a acordes y partituras',
    'newMember.resources.access.step3': 'Descarga pistas de ensayo',
    'newMember.resources.access.step4': 'Accede a videos de entrenamiento',
    'newMember.resources.tech.title': 'Configuración Técnica',
    'newMember.resources.tech.desc': 'Prepara tu equipo y software para una participación efectiva.',
    'newMember.resources.tech.step1': 'Instala las aplicaciones requeridas',
    'newMember.resources.tech.step2': 'Configura el equipo de monitoreo',
    'newMember.resources.tech.step3': 'Configura tus notificaciones',
    'newMember.resources.tech.step4': 'Prueba tu configuración',
    'newMember.schedule.title': 'Gestión de Horarios',
    'newMember.schedule.desc': 'Aprende a ver y responder a las solicitudes de programación de servicios.',
    'newMember.schedule.manage.title': 'Gestiona tu Horario',
    'newMember.schedule.manage.desc': 'Aprende a gestionar efectivamente tu horario de servicios y ensayos.',
    'newMember.schedule.manage.step1': 'Configura bloques de disponibilidad',
    'newMember.schedule.manage.step2': 'Responde a solicitudes de programación',
    'newMember.schedule.manage.step3': 'Usa las funciones del calendario',
    'newMember.schedule.manage.step4': 'Configura recordatorios de horario',
    'newMember.schedule.dates.title': 'Fechas Importantes',
    'newMember.schedule.dates.desc': 'Mantén un registro de eventos clave y fechas límite en nuestro ministerio de adoración.',
    'newMember.schedule.dates.step1': 'Marca las fechas de reunión del equipo',
    'newMember.schedule.dates.step2': 'Anota horarios de servicios especiales',
    'newMember.schedule.dates.step3': 'Registra horarios de ensayo',
    'newMember.schedule.dates.step4': 'Establece fechas de talleres',
    'newMember.communication.title': 'Comunicación',
    'newMember.communication.desc': 'Mantente actualizado con anuncios del equipo e información de ensayos.',
    'newMember.communication.connect.title': 'Mantente Conectado',
    'newMember.communication.connect.desc': 'Conoce nuestros diversos canales de comunicación y cómo mantenerte actualizado.',
    'newMember.communication.connect.step1': 'Únete al grupo de chat del equipo',
    'newMember.communication.connect.step2': 'Suscríbete a los anuncios',
    'newMember.communication.connect.step3': 'Configura notificaciones por correo',
    'newMember.communication.connect.step4': 'Descarga aplicaciones móviles',
    'newMember.communication.guidelines.title': 'Pautas de Comunicación',
    'newMember.communication.guidelines.desc': 'Comprende nuestros protocolos de comunicación y mejores prácticas.',
    'newMember.communication.guidelines.step1': 'Aprende las expectativas de respuesta',
    'newMember.communication.guidelines.step2': 'Comprende protocolos de emergencia',
    'newMember.communication.guidelines.step3': 'Conoce a quién contactar',
    'newMember.communication.guidelines.step4': 'Configura preferencias de comunicación'
  }
}; 