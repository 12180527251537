import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { useLanguage } from '../LanguageContext';
import './index.css';

const videos = Array.from({ length: 20 }, (_, i) => ({
  id: `video-${i + 1}`,
  title: `Video Title ${i + 1}`,
  description: 'Sample video description that explains what this video is about.',
  videoUrl: 'https://www.w3schools.com/html/mov_bbb.mp4',
  thumbnail: i % 2 === 0 ? '/assets/images/full-life-music/compact-black.png' : '/assets/images/full-life-music/compact-white.png',
}));

export function VideoPage({ match }) {
  const { t } = useLanguage();
  const videoId = match.params.videoId;
  const video = videos.find(v => v.id === videoId);

  if (!video) {
    return (
      <div className="video-page">
        <div className="video-page-header">
          <Link to={`${process.env.PUBLIC_URL}/flm/forward`} className="back-button">
            ← {t('videos.backToList')}
          </Link>
          <h1>Video not found</h1>
        </div>
      </div>
    );
  }

  return (
    <div className="video-page">
      <div className="video-page-header">
        <Link to={`${process.env.PUBLIC_URL}/flm/forward`} className="back-button">
          ← {t('videos.backToList')}
        </Link>
        <h1>{video.title}</h1>
      </div>
      <div className="video-page-content">
        <div className="video-player-container">
          <video
            src={video.videoUrl}
            controls
            autoPlay
            className="video-player-main"
          />
        </div>
        <div className="video-info">
          <p className="video-description">{video.description}</p>
        </div>
      </div>
    </div>
  );
} 