import React, { useState, useRef, useEffect } from 'react';
import { useLanguage } from '../LanguageContext';
import './SearchBar.css';

export function SearchBar() {
  const { t, language, translations } = useLanguage();
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const searchRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (searchTerm.length >= 2) {
      const results = searchTranslations(searchTerm);
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  }, [searchTerm, language]);

  const searchTranslations = (term) => {
    const currentTranslations = translations[language];
    const searchTermLower = term.toLowerCase();
    
    const matches = Object.entries(currentTranslations)
      .filter(([key, value]) => {
        const valueLower = value.toLowerCase();
        return valueLower.includes(searchTermLower);
      })
      .map(([key, value]) => ({
        key,
        value,
        // Find the position of the match to highlight it later
        index: value.toLowerCase().indexOf(searchTermLower)
      }))
      .sort((a, b) => {
        // Sort by position of match (earlier matches first)
        if (a.index !== b.index) return a.index - b.index;
        // Then by length (shorter strings first)
        return a.value.length - b.value.length;
      })
      .slice(0, 5); // Take only top 5 matches

    return matches;
  };

  const highlightMatch = (text, searchTerm) => {
    if (!searchTerm) return text;
    
    const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
    return (
      <span>
        {parts.map((part, i) => 
          part.toLowerCase() === searchTerm.toLowerCase() ? 
            <mark key={i} className="search-highlight">{part}</mark> : 
            part
        )}
      </span>
    );
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="search-container" ref={searchRef}>
      <button
        className="search-icon-button"
        onClick={() => setIsOpen(!isOpen)}
        aria-label={t('common.search')}
      >
        <svg 
          className="search-icon" 
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="11" cy="11" r="8"></circle>
          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
        </svg>
      </button>
      
      <div className={`search-popup ${isOpen ? 'open' : ''}`}>
        <form onSubmit={handleSearchSubmit}>
          <input
            ref={inputRef}
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder={t('common.searchPlaceholder')}
            className="search-input"
          />
          {searchTerm && (
            <button
              type="button"
              className="clear-search"
              onClick={() => {
                setSearchTerm('');
                setSearchResults([]);
              }}
              aria-label={t('common.clearSearch')}
            >
              ×
            </button>
          )}
        </form>
        {searchResults.length > 0 && (
          <div className="search-results">
            {searchResults.map((result, index) => (
              <div key={result.key} className="search-result-item">
                {highlightMatch(result.value, searchTerm)}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
} 