import React, { useState, useEffect } from "react";
import { isMobile } from 'react-device-detect';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import AboutTwo from "../component/HomeLayout/homeOne/AboutTwo";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import FooterTwo from "../component/footer/FooterTwo";
import { videoTagString } from 'react-video-tag';
import Helmet from "../component/common/Helmet";
import NuestrasReuniones from "../component/HomeLayout/homeOne/NuestrasReuniones";
import WordPressAPI from '../services/WordPressAPI';
import Contactanos from "../component/HomeLayout/homeOne/Contactanos";
import ExperienciaFullLife from "../component/HomeLayout/homeOne/ExperienciaFullLife/ExperienciaFullLife";
import { Parallax } from "react-parallax";
import HeaderThree from "../component/header/HeaderThree";
import AgendaSemana from "../component/HomeLayout/homeOne/FraseBig";
import Testimonios from "../component/HomeLayout/homeOne/Testimonios";
import HomeSlider from "../component/HomeLayout/homeOne/HomeSlider";
import HomeVideoSlider from "./HomeVideoSlider";
videoTagString({ src: '/assets/images/service/video.mp4', poster: '/assets/images/bg/bg-image-24.jpg', muted:true, autoPlay:true, playsInline:true, loop:true })

const SlideList = [
    {
        textPosition: 'text-left',
        category: '',
        title: 'Bienvenido a Iglesia Full Life',
        description: 'Orden y propósito para tu vida',
        buttonText: 'Contact Us',
        buttonLink: '/contact'
    }
]

const image1 =
  "/assets/images/bg/paralax/_49A3763-min.png";

const Home = () => {
    const [ministerios, setMinisterios] = useState([]);
    const [testimonios, setTestimonios] = useState([]);
    const [loadingMinisterio, setLoadingMinisterios] = useState(true);
    const [loadingTestimonios, setLoadingTestimonios] = useState(true);


    useEffect(() => {
        const api = new WordPressAPI();
        
        // Fetching ministerios
        api.getHomeMinisterios()
           .then(data => {
               setMinisterios(data);
               setLoadingMinisterios(false);
           })
           .catch(error => {
               console.error("Error fetching ministerios:", error);
               setLoadingMinisterios(false);
           });
    
        // Fetching testimonios
        api.getHomeTestimonios()
           .then(data => {
               setTestimonios(data);
               setLoadingTestimonios(false);
           })
           .catch(error => {
               console.error("Error fetching testimonios:", error);
               setLoadingTestimonios(false);
           });
        
    }, []);
 
    
    

    console.log('ministerios',ministerios);


 

 
    return (
            <>
                <Helmet pageTitle="Home" />
                {/* Start Header Area  */}
                {/* <Header logo="light" /> */}
                <HeaderThree logo={isMobile ? "dark" : "light"} />
                {/* End Header Area  */}


                {isMobile ? <HomeSlider /> : <HomeVideoSlider SlideList={SlideList} />}

             

                
                {/* End Slider Area   */} 


                {/* Start About Area */}
                <div className="about-area ptb--120">
                    <AboutTwo />
                </div>
                {/* End About Area */}

                {/* Start Service Area  */}
                <div className="service-area ptb--80  bg_image bg_image--3">
                    <div className="container">
                        {/* <ServiceTwo /> */}
                        <NuestrasReuniones />
                    </div>
                </div>
                {/* End Service Area  */}

                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="portfolio-sacousel-inner mb--55 mb_sm--30">
                        {loadingMinisterio ? (
                            <div>LoadingMinisterio...</div>  // Or your custom loader component
                        ) : (
                            <Portfolio data={ministerios} />
                        )}
                    </div>
                </div>
                {/* End Portfolio Area */}

                {/* Start CounterUp Area */}
                {/* <div className="rn-counterup-area pt--25 pb--110 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h2 className="fontWeight500">Contáctanos</h2>
                                </div>
                            </div>
                        </div>
                        <Contactanos />
                    </div>
                </div> */}
                {/* End CounterUp Area */}
{/* agregar un banner paralax full width */}
 {/* Start Service Area  */}
                <Parallax className="rn-paralax-service" bgImage={image1} strength={900}  blur={5}>
                    <div className="service-area ptb--120">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-12">
                                    <div className="section-title service-style--2 text-center mb--30 mb_sm--0">
                                        <h2>Contáctanos</h2>
                                        <Contactanos  />
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </Parallax>
                {/* End Service Area  */}
                {/* Start Experiencia full life area */}
                <div className="rn-counterup-area pt--200 pb--110 bg_color--1">
                    <div className="container">
                        <ExperienciaFullLife imagePosition="right" />
                    </div>
                </div>
                {/* End  */}

                {/* Start Brand Area */}
                {/* <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Brand Area */}

                {/* Start Footer Area  */}
                <FooterTwo />
                {/* End Footer Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </>
        );
    }

export default Home;