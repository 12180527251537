import React, { useState } from 'react';
import { useLanguage } from '../LanguageContext';
import { useHistory } from 'react-router-dom';
import './index.css';

const newMemberInfo = [
  {
    id: 'getting-started',
    titleKey: 'newMember.getting-started.title',
    descKey: 'newMember.getting-started.desc',
    icon: '🎵'
  },
  {
    id: 'team',
    titleKey: 'newMember.team.title',
    descKey: 'newMember.team.desc',
    icon: '👥'
  },
  {
    id: 'resources',
    titleKey: 'newMember.resources.title',
    descKey: 'newMember.resources.desc',
    icon: '📚'
  },
  {
    id: 'schedule',
    titleKey: 'newMember.schedule.title',
    descKey: 'newMember.schedule.desc',
    icon: '📅'
  },
  {
    id: 'communication',
    titleKey: 'newMember.communication.title',
    descKey: 'newMember.communication.desc',
    icon: '💬'
  }
];

export function NewMemberInfo() {
  const { t } = useLanguage();
  const [hoveredId, setHoveredId] = useState(null);
  const history = useHistory();

  const handleCardClick = (sectionId) => {
    history.push(`${process.env.PUBLIC_URL}/flm/new-members/${sectionId}`);
  };

  return (
    <div className="new-member-info">
      <h2 className="new-member-title">{t('newMember.title')}</h2>
      <ul className="info-list">
        {newMemberInfo.map((item) => (
          <li 
            key={item.id} 
            className="info-item"
            onMouseEnter={() => setHoveredId(item.id)}
            onMouseLeave={() => setHoveredId(null)}
            onClick={() => handleCardClick(item.id)}
          >
            <div className={`info-card ${hoveredId === item.id ? 'hovered' : ''}`}>
              <div className="info-card-icon">{item.icon}</div>
              <h3 className="info-card-title">{t(item.titleKey)}</h3>
              <p className="info-card-desc">{t(item.descKey)}</p>
              <div className="info-card-number">{t(item.titleKey)[0].toUpperCase()}</div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
} 