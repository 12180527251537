import React, { useEffect, useRef, useState } from "react";
import ModalVideo from "react-modal-video";

const HomeVideoSlider = (SlideList) => {
  const [isOpen, setIsOpen] = useState(false);
  const videoRef = useRef(null);
  const openModal = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);

 
  return (
    <>
      <div className="slider-wrapper">
        {/* Start Single Slide */}
        {SlideList.SlideList.map((value, index) => (
          <div
            className="slide slide-style-2 slider-video-bg d-flex align-items-center justify-content-center"
            key={index}
            data-black-overlay="6"
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-8">
                  <div className={`inner ${value.textPosition}`}>
                    {value.category ? <span>{value.category}</span> : ""}
                    {value.title ? (
                      <h1 className="title">{value.title}</h1>
                    ) : (
                      ""
                    )}
                    {value.description ? (
                      <p className="description">{value.description}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="video-inner">
                    <ModalVideo
                      channel="youtube"
                      isOpen={isOpen}
                      videoId="nK29DGSi4D4"
                      onClose={() => setIsOpen(false)}
                    />
                    <button
                      className="video-popup theme-color"
                      onClick={openModal}
                    >
                      <span className="play-icon"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="video-background">
  <video
    ref={videoRef}
    autoPlay={true}
    muted={true}
    loop={true}
    playsInline={true}
    style={{
      width: "100%",
      height: "auto",
      objectFit: "cover",
    }}
    className="background-video"
  >
    <source
      src={`${"/assets/images/service/video.mp4"}`}
      type="video/mp4"
    />
  </video>
</div>

          </div>
        ))}
        {/* End Single Slide */}
      </div>
    </>
  );
};

export default HomeVideoSlider;
