export const resourcesTranslations = {
  en: {
    'resources.songLibrary.title': 'Song Library',
    'resources.songLibrary.desc': 'Browse our complete collection of worship songs',
    'resources.sheetMusic.title': 'Sheet Music',
    'resources.sheetMusic.desc': 'Download sheet music for all instruments',
    'resources.training.title': 'Training Videos',
    'resources.training.desc': 'Watch tutorials and training sessions',
    'resources.schedule.title': 'Schedule',
    'resources.schedule.desc': 'View and manage service schedules',
    'resources.team.title': 'Team Directory',
    'resources.team.desc': 'Connect with your worship team members',
    'resources.equipment.title': 'Equipment Guide',
    'resources.equipment.desc': 'Learn about our audio/visual equipment'
  },
  es: {
    'resources.songLibrary.title': 'Biblioteca de Canciones',
    'resources.songLibrary.desc': 'Explora nuestra colección completa de canciones de adoración',
    'resources.sheetMusic.title': 'Partituras',
    'resources.sheetMusic.desc': 'Descarga partituras para todos los instrumentos',
    'resources.training.title': 'Videos de Entrenamiento',
    'resources.training.desc': 'Mira tutoriales y sesiones de entrenamiento',
    'resources.schedule.title': 'Calendario',
    'resources.schedule.desc': 'Ver y gestionar horarios de servicio',
    'resources.team.title': 'Directorio del Equipo',
    'resources.team.desc': 'Conéctate con los miembros de tu equipo de adoración',
    'resources.equipment.title': 'Guía de Equipos',
    'resources.equipment.desc': 'Aprende sobre nuestro equipo de audio/visual'
  }
}; 