import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useLanguage } from '../LanguageContext';
import './SectionDetail.css';

const sectionContent = {
  'getting-started': {
    icon: '🎵',
    titleKey: 'newMember.getting-started.title',
    descKey: 'newMember.getting-started.desc',
    content: [
      {
        titleKey: 'newMember.getting-started.profile.title',
        descKey: 'newMember.getting-started.profile.desc',
        steps: [
          'newMember.getting-started.profile.step1',
          'newMember.getting-started.profile.step2',
          'newMember.getting-started.profile.step3',
          'newMember.getting-started.profile.step4'
        ]
      },
      {
        titleKey: 'newMember.getting-started.training.title',
        descKey: 'newMember.getting-started.training.desc',
        steps: [
          'newMember.getting-started.training.step1',
          'newMember.getting-started.training.step2',
          'newMember.getting-started.training.step3',
          'newMember.getting-started.training.step4'
        ]
      }
    ]
  },
  'team': {
    icon: '👥',
    titleKey: 'newMember.team.title',
    descKey: 'newMember.team.desc',
    content: [
      {
        titleKey: 'newMember.team.meet.title',
        descKey: 'newMember.team.meet.desc',
        steps: [
          'newMember.team.meet.step1',
          'newMember.team.meet.step2',
          'newMember.team.meet.step3',
          'newMember.team.meet.step4'
        ]
      },
      {
        titleKey: 'newMember.team.expectations.title',
        descKey: 'newMember.team.expectations.desc',
        steps: [
          'newMember.team.expectations.step1',
          'newMember.team.expectations.step2',
          'newMember.team.expectations.step3',
          'newMember.team.expectations.step4'
        ]
      }
    ]
  },
  'resources': {
    icon: '📚',
    titleKey: 'newMember.resources.title',
    descKey: 'newMember.resources.desc',
    content: [
      {
        titleKey: 'newMember.resources.access.title',
        descKey: 'newMember.resources.access.desc',
        steps: [
          'newMember.resources.access.step1',
          'newMember.resources.access.step2',
          'newMember.resources.access.step3',
          'newMember.resources.access.step4'
        ]
      },
      {
        titleKey: 'newMember.resources.tech.title',
        descKey: 'newMember.resources.tech.desc',
        steps: [
          'newMember.resources.tech.step1',
          'newMember.resources.tech.step2',
          'newMember.resources.tech.step3',
          'newMember.resources.tech.step4'
        ]
      }
    ]
  },
  'schedule': {
    icon: '📅',
    titleKey: 'newMember.schedule.title',
    descKey: 'newMember.schedule.desc',
    content: [
      {
        titleKey: 'newMember.schedule.manage.title',
        descKey: 'newMember.schedule.manage.desc',
        steps: [
          'newMember.schedule.manage.step1',
          'newMember.schedule.manage.step2',
          'newMember.schedule.manage.step3',
          'newMember.schedule.manage.step4'
        ]
      },
      {
        titleKey: 'newMember.schedule.dates.title',
        descKey: 'newMember.schedule.dates.desc',
        steps: [
          'newMember.schedule.dates.step1',
          'newMember.schedule.dates.step2',
          'newMember.schedule.dates.step3',
          'newMember.schedule.dates.step4'
        ]
      }
    ]
  },
  'communication': {
    icon: '💬',
    titleKey: 'newMember.communication.title',
    descKey: 'newMember.communication.desc',
    content: [
      {
        titleKey: 'newMember.communication.connect.title',
        descKey: 'newMember.communication.connect.desc',
        steps: [
          'newMember.communication.connect.step1',
          'newMember.communication.connect.step2',
          'newMember.communication.connect.step3',
          'newMember.communication.connect.step4'
        ]
      },
      {
        titleKey: 'newMember.communication.guidelines.title',
        descKey: 'newMember.communication.guidelines.desc',
        steps: [
          'newMember.communication.guidelines.step1',
          'newMember.communication.guidelines.step2',
          'newMember.communication.guidelines.step3',
          'newMember.communication.guidelines.step4'
        ]
      }
    ]
  }
};

export function SectionDetail() {
  const { sectionId } = useParams();
  const { t } = useLanguage();
  const section = sectionContent[sectionId];

  if (!section) {
    return (
      <div className="section-detail">
        <div className="section-header">
          <Link to={`${process.env.PUBLIC_URL}/flm/new-members`} className="back-button">
            ← {t('newMember.backToGuide')}
          </Link>
          <h1>Section not found</h1>
        </div>
      </div>
    );
  }

  return (
    <div className="section-detail">
      <div className="section-header">
        <Link to={`${process.env.PUBLIC_URL}/flm/new-members`} className="back-button">
          ← {t('newMember.backToGuide')}
        </Link>
        <div className="section-title">
          <span className="section-icon">{section.icon}</span>
          <h1>{t(section.titleKey)}</h1>
        </div>
        <p className="section-description">{t(section.descKey)}</p>
      </div>

      <div className="section-content">
        {section.content.map((block, index) => (
          <div key={index} className="content-block">
            <h2>{t(block.titleKey)}</h2>
            <p>{t(block.descKey)}</p>
            <ul className="steps-list">
              {block.steps.map((stepKey, stepIndex) => (
                <li key={stepIndex} className="step-item">
                  <div className="step-number">{stepIndex + 1}</div>
                  <div className="step-text">{t(stepKey)}</div>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
} 