import React from "react";
import EventsCarousel from "../../../EventsCarousel/EventsCarousel";
import "./ExperienciaFullLife.scss";

const ExperienciaFullLife = ({ imagePosition = "left" }) => {
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const currentMonth = new Date().getMonth(); // Get the current month (0-11)
  const Month = months[currentMonth]; // Translate to Spanish

  const handleClick = (nav) => {
    //object literal on x
    const navs = {
      waze: "https://ul.waze.com/ul?venue_id=183369988.1833568811.7302696&overview=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location",
      "google-maps": "https://maps.app.goo.gl/8XgpXjRaThXNp6XeA",
      "apple-maps":
        "https://maps.apple.com/?address=6411%20Taft%20St,%20Hollywood,%20FL%20%2033024,%20United%20States&auid=4752638138237307875&ll=26.025522,-80.216999&lsp=9902&q=Iglesia%20Full%20Life&t=m",
    };
    // open the link in a new tab
    window.open(navs[nav], "_blank");
  };

  const imageComponent = (
    <div className="col-md-6">
      <EventsCarousel />
    </div>
  );

  const contentComponent = (
    <div className="col-md-6 experiencia-fulllife">
      <h2 className="title">Experiencia Full Life</h2>
      <p style={{ textAlign: 'justify' }}>
  ¡El domingo es el día del Señor! Te invitamos a ser parte de 
  la experiencia Full Life. Conoce todos los eventos que hemos
  preparado para {Month} en nuestra iglesia. 
  Haz clic en el ícono que prefieras para obtener las direcciones y acompáñanos.
</p>
      <p>Recuerda, nuestros horarios son</p>
      <p>Domingos: 09:00 a.m, 11:00 a.m y 1:00 p.m.</p>
      <p>6411 Taft St. Hollywood, FL 33024</p>

      <div className="icons-row">
        <i className="icon icon-waze" onClick={() => handleClick("waze")}></i>
        <i
          className="icon icon-google-maps"
          onClick={() => handleClick("google-maps")}
        ></i>
        <i
          className="icon icon-apple-maps"
          onClick={() => handleClick("apple-maps")}
        ></i>
      </div>
    </div>
  );

  return (
    <div className="container experiencia-full-life">
      <div className="row">
        {imagePosition === "left" ? imageComponent : contentComponent}
        {imagePosition === "left" ? contentComponent : imageComponent}
      </div>
    </div>
  );
};

export default ExperienciaFullLife;
