import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FiX, FiMenu } from "react-icons/fi";

class HeaderFour extends Component {
  constructor(props) {
    super(props);
    this.state = { useLightLogo: false, whiteScrollSocial: true };

    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }
  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }
  render() {
    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }

    const { logo, color, headerPosition } = this.props;

    const logoPaths = {
        light: "/assets/images/logo/logo-light.png",
        dark: "/assets/images/logo/logo-black.png",
        "symbol-dark": "/assets/images/logo/logo-black.png",
        "symbol-light": "/assets/images/logo/logo-black.png",
        default: "/assets/images/logo/logo-black.png",
      };
    let logoUrl = this.state.useLightLogo
      ? logoPaths.dark
      : this.props.logo
      ? logoPaths[this.props.logo]
      : logoPaths.default;

    let linkStyle = {};

      if (this.props.logo === "dark") {
        linkStyle.color = "black";
      }

    return (
      <header
        className={`header-area formobile-menu ${headerPosition} ${color}`}
      >
        <div className="header-wrapper" id="header-wrapper">
          <div className="header-left">
            <div className="logo">
            <a href={this.props.homeLink}>
                <img src={logoUrl} alt="Iglesia Full Life" />
              </a>
            </div>
          </div>
          <div className="header-right">
            <nav className="mainmenunav d-lg-block">
              <ul className="mainmenu">
                <li>
                  <Link style={linkStyle} to="/">Home</Link>
                </li>
                {/* <li>
                  <Link style={linkStyle} to="/service">Soy Nuevo</Link>
                </li> */}
                <li>
                  <Link style={linkStyle} to="/conocenos">Nosotros</Link>
                </li>
                <li>
                  <Link style={linkStyle} to="/ministerios">Ministerios</Link>
                </li>
                <li>
                  <Link style={linkStyle} to="/capacitacion">capacitacion</Link>
                </li>
               < li>
                  <Link style={linkStyle} to="/predicas">Predicas</Link>
                </li>
                {/* <li>
                  <Link  to="/service">Recursos</Link>
                </li> */}
                <li>
                  <Link style={linkStyle} to="/contactanos">Contáctanos</Link>
                </li>
              </ul>
            </nav>
            <div className="header-btn">
              <a
                className="rn-btn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.purposegateway.com/client/fulllife"
              >
                <span  style={linkStyle}>Donaciones</span>
              </a>
            </div>
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
              >
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default HeaderFour;
