import React, { useEffect, useState } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import HeaderThree from "../component/header/HeaderThree";
import BreadcrumbFullLife from "./common/FullLifeBreadcrumb/BreadcrumbImg";
import FooterTwo from "../component/footer/FooterTwo";
import ContactThree from "./contact/ContactThree";
import PortfolioList from "./portfolio/PortfolioList";
import WordPressAPI from "../services/WordPressAPI";

const Capacitacion = () => {
  const [loadingCapacitacion, setLoadingCapacitacion] = useState(true);
  const [Capacitacion, setCapacitacion] = useState([]);

  useEffect(() => {
    const api = new WordPressAPI();

    // Fetching Capacitacion
    api
      .getCapacitacion()
      .then((data) => {
        setCapacitacion(data);
        setLoadingCapacitacion(false);
      })
      .catch((error) => {
        console.error("Error fetching Capacitacion:", error);
        setLoadingCapacitacion(false);
      });

    // Fetching testimonios
  }, []);

  console.log("Capacitacion", Capacitacion);
 
  return (
    <React.Fragment>
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
      <PageHelmet pageTitle="Capacitación" />
      <HeaderThree logo="light" />

      {/* Start Breadcrump Area */}
      <BreadcrumbFullLife
        title={"Capacitacion"}
        image={
          "https://hub.iglesiafulllife.org/wp-content/uploads/2024/02/3113-scaled.jpg"
        }
      />
      {/* End Breadcrump Area */}

      {/* Start Service Area */}
      <div className="portfolio-area ptb--120 bg_color--5">
        <div className="portfolio-sacousel-inner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center service-style--3 mb--30">
                   <p>
                   Nuestras capacitaciones en Full Life están diseñadas para fortalecer todas las áreas de tu vida. Te brindamos herramientas prácticas y espirituales que te ayudarán a crecer, desarrollarte y alcanzar tu máximo potencial en cada etapa de tu camino. 
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              {loadingCapacitacion ? (
                <div>Loading Capacitacion...</div> // Or your custom loader component
              ) : (
                <PortfolioList
                  data={Capacitacion}
                  styevariation="text-left mt--40"
                  column="col-lg-4 col-md-6 col-sm-6 col-12"
                  item="6"
                />
              )}
            </div>
            {/* <div className="row">
              <div className="col-lg-12">
                <div className="view-more-btn mt--60 text-center">
                  <a className="rn-button-style--2 btn-solid" href="/portfolio">
                    <span>View More Project</span>
                  </a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <FooterTwo />
    </React.Fragment>
  );
};

export default Capacitacion;
