import React, { useState } from 'react';
import { ResourcesTiles } from './resources/ResourcesTiles';
import './index.css';
import { LanguageProvider, useLanguage } from './LanguageContext';
import { VideosGrid } from './forward';
import { VideoPage } from './forward/VideoPage';
import { SectionDetail } from './new-members/SectionDetail';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import { NewMemberInfo } from './new-members';
import { SearchBar } from './common/SearchBar';

function FlmContent() {
  const { t, toggleLanguage, language } = useLanguage();
  const location = useLocation();
  const history = useHistory();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleNavClick = (section, e) => {
    e.preventDefault();
    history.push(`${process.env.PUBLIC_URL}/flm/${section}`);
    setIsMobileMenuOpen(false);
  };

  const isActive = (section) => {
    return location.pathname.includes(section);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="flm-container">
      <nav className="flm-nav">
        <div className="nav-content">
          <div className="nav-logo">
            <img 
              src="/assets/images/full-life-music/logo.png" 
              alt="Full Life Music" 
              className="logo-image"
              style={{ filter: 'invert(100%)' }}
            />
            <h1 className="nav-title">Full Life Music</h1>
          </div>
          <div className="nav-controls">
            <SearchBar />
            <button 
              className={`mobile-menu-button ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}
              onClick={toggleMobileMenu}
              aria-label="Toggle menu"
            >
              <div className="mobile-menu-icon"></div>
            </button>
          </div>
          <div className={`nav-links ${isMobileMenuOpen ? 'open' : ''}`}>
            <a 
              href="#resources" 
              className={`nav-link ${isActive('resources') ? 'active' : ''}`}
              onClick={(e) => handleNavClick('resources', e)}
            >
              {t('nav.resources')}
            </a>
            <a 
              href="#new-members" 
              className={`nav-link ${isActive('new-members') ? 'active' : ''}`}
              onClick={(e) => handleNavClick('new-members', e)}
            >
              {t('nav.newMembers').replace(' ', '\u00A0')}
            </a>
            <a 
              href="#forward" 
              className={`nav-link ${isActive('forward') ? 'active' : ''}`}
              onClick={(e) => handleNavClick('forward', e)}
            >
              {t('nav.videos')}
            </a>
            <button 
              className="lang-toggle"
              onClick={() => {
                toggleLanguage();
                setIsMobileMenuOpen(false);
              }}
            >
              {language === 'en' ? 'Español' : 'English'}
            </button>
            <SearchBar />
          </div>
        </div>
      </nav>
      <main className="flm-main">
        <Switch>
          <Route exact path={`${process.env.PUBLIC_URL}/flm/forward/:videoId`} component={VideoPage} />
          <Route exact path={`${process.env.PUBLIC_URL}/flm/forward`} component={VideosGrid} />
          <Route exact path={`${process.env.PUBLIC_URL}/flm/resources`} component={ResourcesTiles} />
          <Route exact path={`${process.env.PUBLIC_URL}/flm/new-members/:sectionId`} component={SectionDetail} />
          <Route exact path={`${process.env.PUBLIC_URL}/flm/new-members`} component={NewMemberInfo} />
          <Route path={`${process.env.PUBLIC_URL}/flm`} component={VideosGrid} />
        </Switch>
      </main>
    </div>
  );
}

function Flm() {
  return (
    <LanguageProvider>
      <FlmContent />
    </LanguageProvider>
  );
}

export default Flm;
