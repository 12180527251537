import React, { createContext, useContext, useState } from 'react';
import { commonTranslations } from './common/translations';
import { forwardTranslations } from './forward/translations';
import { newMemberTranslations } from './new-members/translations';
import { resourcesTranslations } from './resources/translations';

const LanguageContext = createContext({
  language: 'en',
  toggleLanguage: () => {},
  t: (key) => '',
  translations: {}
});

// Merge all translations
const translations = {
  en: {
    ...commonTranslations.en,
    ...forwardTranslations.en,
    ...newMemberTranslations.en,
    ...resourcesTranslations.en
  },
  es: {
    ...commonTranslations.es,
    ...forwardTranslations.es,
    ...newMemberTranslations.es,
    ...resourcesTranslations.es
  }
};

export function LanguageProvider({ children }) {
  const [language, setLanguage] = useState(() => {
    // Get saved language from localStorage or default to 'en'
    return localStorage.getItem('preferred_language') || 'en';
  });

  const toggleLanguage = () => {
    setLanguage(prev => {
      const newLang = prev === 'en' ? 'es' : 'en';
      // Save to localStorage whenever language changes
      localStorage.setItem('preferred_language', newLang);
      return newLang;
    });
  };

  const t = (key) => {
    return translations[language][key] || key;
  };

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage, t, translations }}>
      {children}
    </LanguageContext.Provider>
  );
}

export function useLanguage() {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
} 