import React, { Component } from "react";
import { Link } from "react-router-dom";

const PortfolioListContent = [
  {
    image: "image-1",
    category: "Development",
    title: "Getting tickets to the big show",
  },
  {
    image: "image-2",
    category: "Development",
    title: "Getting tickets to the big show",
  },
  {
    image: "image-3",
    category: "Development",
    title: "Getting tickets to the big show",
  },
  {
    image: "image-4",
    category: "Development",
    title: "Getting tickets to the big show",
  },
  {
    image: "image-3",
    category: "Development",
    title: "Getting tickets to the big show",
  },
  {
    image: "image-4",
    category: "Development",
    title: "Getting tickets to the big show",
  },
];

class PortfolioList extends Component {
  render() {
    const { column, styevariation, data } = this.props;
    const list = PortfolioListContent.slice(0, this.props.item);
    const datatoshow = data || list;
    return (
      <React.Fragment>
        {datatoshow.map((value, index) => (
          <div className={`${column}`} key={index}>
            <div className={`portfolio ${styevariation}`}>
              <style>
                {`.thumbnail-${index} {
                        background-image: url(${value.image}) !important;
                    }`}
              </style>
              <div className="thumbnail-inner">
              <div className={`thumbnail thumbnail-${index}`}></div>
                <div className={`bg-blr-image ${value.image}`}></div>
              </div>
              <div className="content">
                <div className="inner">
                  <p>{value.category}</p>
                  <h4>
                    <a href={`https://www.iglesiafulllife.org/ministerios/${value.id}`}>{value.title}</a>
                  </h4>
                  <div className="portfolio-button">
                    <a className="rn-btn" href={`https://www.iglesiafulllife.org/ministerios/${value.id}`} >
                      Ver ministerio
                    </a>
                  </div>
                </div>
              </div>
              <Link className="link-overlay" to={`/ministerios/${value.id}`}></Link>
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }
}
export default PortfolioList;
