import React from "react";
import Slider from "react-slick";
import { portfolioSlick2 } from "../../../page-demo/script";
import { Link } from "react-router-dom";

const PortfolioList = [
  {
    image: "image-1",
    category: "Development",
    title: " Getting tickets to the big show",
  },
  {
    image: "image-2",
    category: "Development",
    title: " Getting tickets to the big show",
  },
  {
    image: "image-3",
    category: "Development",
    title: " Getting tickets to the big show",
  },
  {
    image: "image-4",
    category: "Development",
    title: " Getting tickets to the big show",
  },
  {
    image: "image-3",
    category: "Development",
    title: " Getting tickets to the big show",
  },
  {
    image: "image-4",
    category: "Development",
    title: " Getting tickets to the big show",
  },
];

const Portfolio = (props) => {
  const data = props.data || PortfolioList; // Use props if they exist, otherwise use PortfolioList
  console.log('incoming data', data);
  const title = "Nuestros Ministerios";
  const description =
    "Nuestros ministerios congregacionales son el lugar perfecto para conectarte con Full Life. Ofrecemos una variedad de oportunidades para que puedas crecer espiritualmente y ser edificado de acuerdo a tus intereses y tu perfil.";

  return (
    <>
      <div className="portfolio-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="section-title">
                <h2>{title}</h2>
                <p>{description}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="portfolio-slick-activation mt--70 mt_sm--40">
          <Slider {...portfolioSlick2}>
            {data.map((value, index) => (
              <div className="portfolio" key={index}>
                 <style>
                    {`.thumbnail-${index} {
                        background-image: url(${value.image}) !important;
                    }`}
                </style>
                <div className="thumbnail-inner">
                <div className={`thumbnail thumbnail-${index}`}></div>
                  <div className={`bg-blr-image ${value.image}`}></div>
                </div>
                <div className="content">
                  <div className="inner">
                    {/* <p>{value.category}</p> */}
                    <h4 className="title">
                      <a href="/#">{value.title}</a>
                    </h4>
                    <div className="portfolio-button">
                     <a className="rn-btn" href={value.id ? `/ministerios/${value.id}` : "#"}>
  {value.category}
</a>
                    </div>
                  </div>
                </div>
                <Link className="link-overlay" to={`/ministerios/${value.id}`}></Link>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Portfolio;
