export const commonTranslations = {
  en: {
    'nav.newMembers': 'New Members',
    'nav.resources': 'Resources',
    'nav.videos': 'Forward',
    'common.search': 'Search',
    'common.searchPlaceholder': 'Search Full Life Music...',
    'common.clearSearch': 'Clear search'
  },
  es: {
    'nav.newMembers': 'Nuevos Miembros',
    'nav.resources': 'Recursos',
    'nav.videos': 'Forward',
    'common.search': 'Buscar',
    'common.searchPlaceholder': 'Buscar en Full Life Music...',
    'common.clearSearch': 'Limpiar búsqueda'
  }
}; 