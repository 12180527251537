import React, { useState, useEffect, useRef } from 'react';
import { useLanguage } from '../LanguageContext';
import { useHistory } from 'react-router-dom';
import './index.css';

const videos = Array.from({ length: 20 }, (_, i) => ({
  id: `video-${i + 1}`,
  title: `Video Title ${i + 1}`,
  description: 'Sample video description that explains what this video is about.',
  videoUrl: 'https://www.w3schools.com/html/mov_bbb.mp4',
  thumbnail: i % 2 === 0 ? '/assets/images/full-life-music/compact-black.png' : '/assets/images/full-life-music/compact-white.png',
}));

export function VideosGrid() {
  const { t } = useLanguage();
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const [loadingVideoId, setLoadingVideoId] = useState(null);
  const searchInputRef = useRef(null);
  const videoRefs = useRef({});

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  const handleVideoClick = (video) => {
    history.push(`${process.env.PUBLIC_URL}/flm/forward/${video.id}`);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredVideos = videos.filter(video => 
    video.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    video.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="videos-container">
      <div className="videos-header">
        <h2>{t('videos.title')}</h2>
        <div className="search-container">
          <input
            ref={searchInputRef}
            type="text"
            className="search-input"
            placeholder="Search videos..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          {searchTerm && (
            <button 
              className="clear-search" 
              onClick={() => {
                setSearchTerm('');
              }}
            >
              ×
            </button>
          )}
        </div>
      </div>

      <div className="videos-grid">
        {filteredVideos.map((video) => (
          <div className="video-item" key={video.id}>
            <div 
              className="thumbnail-card" 
              onClick={() => handleVideoClick(video)}
              onMouseEnter={(e) => {
                const videoEl = videoRefs.current[video.id];
                if (videoEl) {
                  setLoadingVideoId(video.id);
                  videoEl.style.opacity = '1';
                  videoEl.src = video.videoUrl;
                  videoEl.load();
                  videoEl.play().catch(() => {
                    setLoadingVideoId(null);
                  });
                }
              }}
              onMouseLeave={(e) => {
                const videoEl = videoRefs.current[video.id];
                if (videoEl) {
                  setLoadingVideoId(null);
                  videoEl.style.opacity = '0';
                  videoEl.pause();
                  videoEl.removeAttribute('src');
                  videoEl.load();
                }
              }}
            >
              <img
                src={video.thumbnail}
                alt={video.title}
                className="thumbnail-image"
              />
              <video
                ref={el => videoRefs.current[video.id] = el}
                className="preview-video"
                muted
                loop
                playsInline
                preload="none"
                onPlaying={() => setLoadingVideoId(null)}
                onError={() => setLoadingVideoId(null)}
              />
              {loadingVideoId === video.id && (
                <div className="loading-spinner">
                  <svg className="spinner-svg" viewBox="0 0 50 50">
                    <circle className="spinner-circle" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                  </svg>
                </div>
              )}
              <div className="video-title">
                {video.title}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
} 